#top .mv {
  position: relative;
  z-index: 2;
  min-width: 1200px;
}

#top .mv .inner {
  padding: 0 50px;
  box-sizing: border-box;
  width: 100%;
}

#top .mv__img img {
  width: 100%;
}

#top .mv__txt {
  position: absolute;
  left: 0;
  top: 0;
  padding: 33.33333% 0 0 7.25%;
}

#top .mv__txt .jp {
  font-size: 40px;
  letter-spacing: 0.06em;
  font-weight: bold;
  color: #fff;
  display: block;
  text-align: left;
  margin-bottom: 13px;
}

#top .mv__txt .en {
  font-size: 20px;
  letter-spacing: 0.06em;
  font-weight: bold;
  color: #fff;
  display: block;
  text-align: left;
  line-height: 180%;
}

#top .mv__bg1 {
  position: absolute;
  left: 0;
  top: 0;
  padding: 16.66667% 0 0 0;
}

#top .mv__bg2 {
  position: absolute;
  top: -50px;
  right: 18px;
}

#top .about {
  margin-bottom: 120px;
}

#top .about__wrap {
  background: #f7f5e5;
  margin-top: -225px;
  min-width: 1200px;
}

#top .about__inner {
  position: relative;
  padding: 353px 0 100px 0;
  z-index: 1;
  width: 1060px;
  min-width: 1060px;
  margin: 0 auto;
}

#top .about__inner .bg1 {
  position: absolute;
  left: 2px;
  top: 677px;
}

#top .about__inner .bg2 {
  position: absolute;
  right: 12px;
  top: 442px;
  z-index: 2;
}

#top .about__inner .img1 {
  position: absolute;
  left: -80px;
  top: 343px;
}

#top .about__inner .img2 {
  position: absolute;
  right: -50px;
  top: 359px;
  z-index: 1;
}

#top .about__inner .img3 {
  position: absolute;
  right: -7px;
  top: 741px;
}

#top .about__catch {
  font-size: 28px;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  font-weight: bold;
  line-height: 242.85714%;
  margin: 61px auto 72px auto;
}

#top .about__inner2 {
  padding-top: 120px;
  position: relative;
  min-width: 1200px;
}

#top .about__inner2 .img {
  width: 60.83333%;
}

#top .about__inner2 .img img {
  width: 100%;
}

#top .about__inner2 .img2 {
  margin-left: 39.16667%;
}

#top .about__inner2 .detail {
  width: 28.33333%;
  padding-left: 60px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  padding: 0 0 0 65.83333%;
}

#top .about__inner2 .detail .ttl {
  font-size: 28px;
  letter-spacing: 0.1em;
  line-height: 128.57143%;
  margin-bottom: 26px;
  font-weight: bold;
  text-align: left;
}

#top .about__inner2 .detail .txt {
  font-size: 15px;
  line-height: 160%;
  margin-bottom: 30px;
  text-align: left;
}

#top .about__inner2 .detail .btn-type2 {
  margin-bottom: 20px;
}

#top .about__inner2 .detail .btn-type2:last-of-type {
  margin-bottom: 0;
}

#top .about__inner2 .detail2 {
  padding: 0 0 0 5.83333%;
}

#top .about__inner2 .bg3 {
  position: absolute;
  right: 6.83333%;
  top: 24px;
  z-index: 2;
}

#top .about__inner2 .bg4 {
  position: absolute;
  right: 18.75%;
  top: 56px;
  z-index: 2;
}

#top .about__inner2 .bg5 {
  position: absolute;
  right: 57.5%;
  bottom: -81px;
  z-index: 2;
}

#top .about__content {
  position: relative;
  margin: 0 auto;
  min-width: 1200px;
}

#top .info {
  background: #f7f5e5;
  box-sizing: border-box;
  position: relative;
  min-width: 1200px;
}

#top .info .inner {
  padding: 90px 0 120px 0;
}

#top .info .section-heading {
  margin-bottom: 70px;
}

#top .info__list {
  font-size: 15px;
  letter-spacing: 0.06em;
  display: flex;
  text-align: center;
  width: 910px;
  margin: 0 auto 42px auto;
}

#top .info__list:last-of-type {
  margin-bottom: 0;
}

#top .info__list dt {
  margin-right: 10px;
  width: 100px;
}

#top .info__list dd {
  width: 800px;
  text-align: left;
}

#top .info__list dd a,
#top .info__list dd a:link,
#top .info__list dd a:visited {
  color: #000;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

#top .info__list dd a:hover {
  color: #000;
  opacity: .7;
  text-decoration: none;
}

#top .info__list dd a {
  color: #0fa98e;
  display: inline-block;
  margin-left: 10px;
}

#top .info .btn-type1 {
  margin-top: 77px;
}

#top .info .bg1 {
  position: absolute;
  right: 74px;
  top: -53px;
}
