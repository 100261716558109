// ---------------------------------
$color-black: #000;
$color-white: #fff;
$color-yellow: #ff0;
$color-green: #0fa98e;
$default-color: $color-black;
$contents-width: 1000px;
$font-en: 'Lato', sans-serif;

// ---------------------------------
@mixin txt_link_opacity($deco:none, $color:$default-color, $color2:$default-color, $hover-deco:none) {
	a,
	a:link,
	a:visited {
		color: $color;
		text-decoration: $deco;
		@include transition(.2);
	}

	a:hover {
		color: $color2;
		opacity: .7;
		text-decoration: $hover-deco;
	}
}

@mixin txt_link_color($deco:none, $color:$default-color, $color2:$default-color, $hover-deco:none) {
	a,
	a:link,
	a:visited {
		color: $color;
		text-decoration: $deco;
		@include transition(.2);
	}

	a:hover {
		color: $color2;
		text-decoration: $hover-deco;
	}
}

@mixin btn_opacity($deco:none, $color:$default-color, $color2:$default-color, $hover-deco:none) {
	a,
	a:link,
	a:visited {
		text-decoration: $deco;
		@include transition(.2);
	}

	a:hover {
		opacity: .7;
		text-decoration: $hover-deco;
	}
}

@mixin cf {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin transition($time:.2, $prop:all, $easing:ease-in-out) {
	transition: $prop #{$time}s $easing;
}

@mixin eg() {
	@supports (-ms-ime-align:auto) {
		@content;
	}
}

@mixin ie() {
	@media all and (-ms-high-contrast:none) {
		@content;
	}
}
