@import "common/var";
#top{

	//mv -----------------------------------------------
	.mv{
		position: relative;
		z-index: 2;
		min-width: 1200px;

		.inner{
			padding: 0 50px;
			box-sizing: border-box;
			width: 100%;
		}

		&__img{
			img{
				width: 100%;
			}
		}

		&__txt{
			position: absolute;
			left: 0;
			top: 0;
			padding: percentage(400/1200) 0 0 percentage(87/1200);

			.jp{
				font-size: 40px;
				letter-spacing: 0.06em;
				font-weight: bold;
				color: $color-white;
				display: block;
				text-align: left;
				margin-bottom: 13px;
			}

			.en{
				font-size: 20px;
				letter-spacing: 0.06em;
				font-weight: bold;
				color: $color-white;
				display: block;
				text-align: left;
				line-height: percentage(36/20);
			}
		}

		&__bg1{
			position: absolute;
			left: 0;
			top: 0;
			padding: percentage(200/1200) 0 0 0;
		}

		&__bg2{
			position: absolute;
			top: -50px;
			right: 18px;
		}
	}


	//about -----------------------------------------------
	.about{
		margin-bottom: 120px;

		&__wrap{
			background: #f7f5e5;
			margin-top: -225px;
			min-width: 1200px;
		}

		&__inner{
			position: relative;
			padding: 353px 0 100px 0;
			z-index: 1;
			width: 1060px;
			min-width: 1060px;
			margin: 0 auto;

			.bg1{
				position: absolute;
				left: 2px;
				top: 677px;
			}

			.bg2{
				position: absolute;
				right: 12px;
				top: 442px;
				z-index: 2;
			}

			.img1{
				position: absolute;
				left: -80px;
				top: 343px;
			}

			.img2{
				position: absolute;
				right: -50px;
				top: 359px;
				z-index: 1;
			}

			.img3{
				position: absolute;
				right: -7px;
				top: 741px;
			}
		}

		&__catch{
			font-size: 28px;
			letter-spacing: 0.1em;
			text-indent: 0.1em;
			font-weight: bold;
			line-height: percentage(68/28);
			margin: 61px auto 72px auto;
		}

		&__inner2{
			padding-top: 120px;
			position: relative;
			min-width: 1200px;

			.img{
				width: percentage(730px/1200px);

				img{
					width: 100%;
				}
			}

			.img2{
				margin-left: percentage(470px/1200px)
			}

			.detail{
				width: percentage(340px/1200px);
				padding-left: 60px;
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
				left: 0;
				padding: 0 0 0 percentage(790px/1200px);

				.ttl{
					font-size: 28px;
					letter-spacing: 0.1em;
					line-height: percentage(36/28);
					margin-bottom: 26px;
					font-weight: bold;
					text-align: left;
				}

				.txt{
					font-size: 15px;
					line-height: percentage(24/15);
					margin-bottom: 30px;
					text-align: left;
				}

				.btn-type2{
					margin-bottom: 20px;

					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}

			.detail2{
				padding: 0 0 0 percentage(70px/1200px);
			}

			.bg3{
				position: absolute;
				right: percentage(82px/1200px);
				top: 24px;
				z-index: 2;
			}

			.bg4{
				position: absolute;
				right: percentage(225px/1200px);
				top: 56px;
				z-index: 2;
			}

			.bg5{
				position: absolute;
				right: percentage(690px/1200px);
				bottom: -81px;
				z-index: 2;
			}
		}

		&__content{
			position: relative;
			margin: 0 auto;
			min-width: 1200px;
		}
	}


	//info -----------------------------------------------
	.info{
		background: #f7f5e5;
		box-sizing: border-box;
		position: relative;
		min-width: 1200px;

		.inner{
			padding: 90px 0 120px 0;
		}

		.section-heading{
			margin-bottom: 70px;
		}

		&__list{
			font-size: 15px;
			letter-spacing: 0.06em;
			display: flex;
			text-align: center;
			width: 910px;
			margin: 0 auto 42px auto;

			&:last-of-type{
				margin-bottom: 0;
			}

			dt{
				margin-right: 10px;
				width: 100px;
			}

			dd{
				width: 800px;
				text-align: left;
				@include txt_link_opacity;

				a{
					color: $color-green;
					display: inline-block;
					margin-left: 10px;
				}
			}
		}

		.btn-type1{
			margin-top: 77px;
		}

		.bg1{
			position: absolute;
			right: 74px;
			top: -53px;
		}
	}
}
//top
